import React, { Suspense } from 'react';
import * as ReactDOMClient from 'react-dom/client';
import './assets/css/tailwind.output.css';
import App from './App';
import { SidebarProvider } from './context/SidebarContext';
import ThemedSuspense from './components/ThemedSuspense';
import { Windmill } from '@windmill/react-ui';
import * as serviceWorker from './serviceWorker';

const container = document.getElementById('root');

const root = ReactDOMClient.createRoot(container);

root.render(
  <SidebarProvider>
    <Suspense fallback={<ThemedSuspense />}>
      <Windmill usePreferences>
        <App />
      </Windmill>
    </Suspense>
  </SidebarProvider>
);

serviceWorker.register();
